export const score=
{
    id:-6,
    name:'永远常在',
    author:'千与千寻',
    power:'PUBLIC',
    status:'APPROVED',
    typeId:1,
    userId:3,
    scoreType:{
        id:1,
        type:'五线谱'
    },
    user:{
        id:3,
        nickname:'p9958'
    },
    scoreImageList:[
        {
            file:{url:require("./永远常在 いつも何度でも木村弓-1.jpg")}
        },
        {
            file:{url:require("./永远常在 いつも何度でも木村弓-2.jpg")}
        },
        {
            file:{url:require("./永远常在 いつも何度でも木村弓-3.jpg")}
        }
    ],
}
