export const score=
{
    id:-2,
    name:'平凡的一天',
    author:'毛不易',
    power:'PUBLIC',
    status:'APPROVED',
    typeId:1,
    userId:3,
    scoreType:{
        id:1,
        type:'五线谱'
    },
    user:{
        id:3,
        nickname:'p9958'
    },
    scoreImageList:[
        {
            file:{url:require("./平凡的一天-毛不易-1.png")}
        },
        {
            file:{url:require("./平凡的一天-毛不易-2.png")}
        },
        {
            file:{url:require("./平凡的一天-毛不易-3.png")}
        },
        {
            file:{url:require("./平凡的一天-毛不易-4.png")}
        },
        {
            file:{url:require("./平凡的一天-毛不易-5.png")}
        }
    ],
}
