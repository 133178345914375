const importFn = require.context('./scores', true, /\.js$/);
const scs=[];
importFn.keys().forEach(key => {
    // console.log(key);
    // console.log(importFn(key).score)
    scs.push(importFn(key).score);
});

export const scores=scs;


// export const scores=[
//     {
//         id:1,images:[
//             require("./谱.jpg"),
//             require("./谱.jpg")],
//         name:'亲爱的旅人',author:'千与千寻',tag:'钢琴谱'
//     },
//     {
//         id:1,images:[
//             require("./谱.jpg"),
//             require("./谱.jpg")],
//         name:'亲爱的旅人',author:'千与千寻',tag:'钢琴谱'
//     },
//     {
//         id:1,images:[
//             require("./谱.jpg"),
//             require("./谱.jpg")],
//         name:'亲爱的旅人',author:'千与千寻',tag:'钢琴谱'
//     },
//     {
//         id:1,images:[
//             require("./谱.jpg"),
//             require("./谱.jpg")],
//         name:'亲爱的旅人',author:'千与千寻',tag:'钢琴谱'
//     },
//     {
//         id:1,images:[
//             require("./谱.jpg"),
//             require("./谱.jpg")],
//         name:'亲爱的旅人',author:'千与千寻',tag:'钢琴谱'
//     },
//     {
//         id:1,images:[
//             require("./谱.jpg"),
//             require("./谱.jpg")],
//         name:'亲爱的旅人',author:'千与千寻',tag:'钢琴谱'
//     },
//     {
//         id:1,images:[
//             require("./谱.jpg"),
//             require("./谱.jpg")],
//         name:'亲爱的旅人',author:'千与千寻',tag:'钢琴谱'
//     }
// ]