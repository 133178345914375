<template>
  <div class="scoreContent">
    <h1><slot></slot></h1>
    <ul class="score-list">
        <li v-for="(item,index) in scores" :key="item.id">
            <Score :score="item" @collect="collect(index)" @cancelCollect="cancelCollect(index)" :isCollect="judgeIsCollect(item.id)"/>
        </li>
    </ul>
  </div>
</template>

<script>
import axios from '../../../utils/request';
import qs from "qs";
import Score from './score.vue'
import Message from '../../../components/library/Message';
import {ref} from "vue"
export default {
  name: 'ScoreContent',
  props:{
    scores:{
        type:Array,
        default:null
    }
  },
  components: { Score },
  
  setup (props) {
    let collectList=ref([]);
    let user=JSON.parse(localStorage.getItem("user"));
    if(user!=null){
      axios.get("/collect/getCollectList").then((res)=>{
        collectList.value=res;
      })
    }
    const judgeIsCollect=(id)=>{
      for(let item of collectList.value){
        if(item.scoreId==id){
          return true;
        }
      }
      return false;
    }
    const collect=(index)=>{
      let collectObj={};
      collectObj.userId=user.id;
      collectObj.scoreId=props.scores[index].id;
      axios.post("/collect/addCollect",collectObj).then((res)=>{
        collectList.value.push(res);
        Message({type:"success",text:"收藏成功！"});
      }).catch((err)=>{
        Message({type:"error",text:err.message});
      });
    }
    const cancelCollect=(index)=>{
      let p=null;
      //console.log("debug");
      for(let i=0;i<collectList.value.length;i++){
        if(collectList.value[i].scoreId==props.scores[index].id){
          p=i;
        }
      }
      if(p==null){
        return;
      }
      axios.post("/collect/deleteCollectById",qs.stringify({id:collectList.value[p].id})).then(()=>{
        collectList.value.splice(p,1);
        Message({type:"success",text:"已取消"});
      }).catch((err)=>{
        Message({type:"error",text:err.message});
      })
    }
    return {judgeIsCollect,collect,cancelCollect};
    //console.log(props.scores);
  }
}
</script>

<style scoped lang='less'>
// @import "../../../assets/styles/variables.less";
.scoreContent {
    h1{
        line-height: 80px;
        text-align: center;
    }
   
    background: #fff;
    padding-bottom: 50px;
    width: 1200px;
    margin: auto;
    //   height: 2900px;
    .score-list {
        margin: auto;
        width: 1100px;
        //border: solid;
        //width: 990px;
        display: flex;
        flex-wrap: wrap;
        //justify-content: space-around;
        li {
            width: 240px;
            //height: 350px;
            margin-right: 40px;
            margin-bottom: 10px;
            &:nth-last-child(-n+4) {
                margin-bottom: 0;
            }
            &:nth-child(4n) {
                margin-right: 0;
            }
        }
    }
}

</style>