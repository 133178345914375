export const score=
{
    id:-3,
    name:'七里香',
    author:'周杰伦',
    power:'PUBLIC',
    status:'APPROVED',
    typeId:1,
    userId:3,
    scoreType:{
        id:1,
        type:'五线谱'
    },
    user:{
        id:3,
        nickname:'p9958',
    },
    scoreImageList:[
        {
            file:{url:require("./七里香-周杰伦-1.png")}
        },
        {
            file:{url:require("./七里香-周杰伦-2.png")}
        },
        {
            file:{url:require("./七里香-周杰伦-3.png")}
        },
        {
            file:{url:require("./七里香-周杰伦-4.png")}
        },
        {
            file:{url:require("./七里香-周杰伦-5.png")}
        },
        {
            file:{url:require("./七里香-周杰伦-6.png")}
        }
    ],
}
