<template>
<div>
<Carousel class="carousel" :sliders="imgs"/>
<ScoreContent class="sc" :scores='scs'><i class="iconfont icon-recomment">&nbsp;</i>乐谱推荐</ScoreContent>
</div>
</template>

<script>
import axios from "../../utils/request"
import Carousel from './components/carousel.vue'
import ScoreContent from './components/scoreContent.vue'
import {images} from '../../assets/images/carousel/index'
import {scores} from "../../assets/images/score/index"
// import {getAllScores} from '../../api/score'
//import qs from 'qs'
import {ref} from 'vue'
export default {
    name:'Home',
    components:{
        Carousel,
        ScoreContent
    },
    setup(){
        const imgs=ref([]);
        imgs.value=images;
        for(let img of imgs.value){
            let image=new Image();
            image.src=img;
        }
        
        const scs=ref([])
        scs.value=scores;
        //console.log("allScore",getAllScores());
        
        // getRandomScore(10).then((res)=>{
        //     scs.value=res.data;
        // })
        axios.get("/score/getRandomScoreList",{params:{num:10}}).then((res)=>{
            //console.log("res:",res);
            scs.value=res;
        })
        //console.log(scs);

        return {imgs,scs};
    }
}
</script>

<style lang="less" scoped>
.carousel{
    margin-top:20px;
}
.sc{
    margin-top:20px;
}
i{
    font-size: 30px;
}
</style>