export const score=
{
    id:-4,
    name:'像我这样的人',
    author:'毛不易',
    power:'PUBLIC',
    status:'APPROVED',
    typeId:1,
    userId:3,
    scoreType:{
        id:1,
        type:'五线谱'
    },
    user:{
        id:3,
        nickname:'p9958'
    },
    scoreImageList:[
        {
            file:{url:require("./像我这样的人-毛不易-1.png")}
        },
        {
            file:{url:require("./像我这样的人-毛不易-2.png")}
        },
        {
            file:{url:require("./像我这样的人-毛不易-3.png")}
        }
    ],
}
