<template>
<div>
    <div class="imgContent">
        <div @mouseenter="stop()" @mouseleave="start()" :class="imgRef[0].cla" @click="change(0)">
            <img :src="imgs[imgRef[0].img].src"/>
            <span v-show="imgs[imgRef[0].img].tag">{{imgs[imgRef[0].img].tag}}</span>
        </div>
        <div @mouseenter="stop()" @mouseleave="start()" :class="imgRef[1].cla" @click="change(1)">
            <img :src="imgs[imgRef[1].img].src"/>
            <span v-show="imgs[imgRef[1].img].tag">{{imgs[imgRef[1].img].tag}}</span>
        </div>
        <div @mouseenter="stop()" @mouseleave="start()" :class="imgRef[2].cla" @click="change(2)">
            <img :src="imgs[imgRef[2].img].src"/>
            <span v-show="imgs[imgRef[2].img].tag">{{imgs[imgRef[2].img].tag}}</span>
        </div>
        <div class="points">
            <div v-for="i in imgs.length" :key="i" :class="{active:(i-1)==pActive}" @mouseover="mouChange(i-1)"></div>
        </div>
    </div>
</div>
</template>

<script>
import {ref,toRef, watch,onUnmounted} from 'vue'
export default {
    name:'Carousel',
    props: {
        sliders: {
            type: Array,
            default: () => []
        },
        duration: {
            type: Number,
            default: 2000
        },
        autoPlay: {
            type: Boolean,
            default: false
        }
    },
    setup (props) {
        const imgs=ref(null);
        // imgs.value=['img0','img1','img2','img3'];
        imgs.value=toRef(props.sliders);
        imgs.value=imgs.value._object;
        const imgRef=ref([]);
        imgRef.value=[
            {cla:'eleLeft',img:0},
            {cla:'eleMid',img:1},
            {cla:'eleRight',img:2}
        ]

        const move=(dir)=>{
            let i=0;
            if(dir=="right"){
                for(i=0;i<imgRef.value.length;i++){
                    if(imgRef.value[i].cla=='eleLeft'){
                        imgRef.value[i].cla='eleMid';
                    }else if(imgRef.value[i].cla=='eleMid'){
                        imgRef.value[i].cla='eleRight';
                    }else{
                        imgRef.value[i].img=(imgRef.value[i].img-3+imgs.value.length)%imgs.value.length;
                        imgRef.value[i].cla='eleLeft';
                    }
                }
            }else{
                for(i=0;i<imgRef.value.length;i++){
                    if(imgRef.value[i].cla=='eleLeft'){
                        imgRef.value[i].img=(imgRef.value[i].img+3+imgs.value.length)%imgs.value.length;
                        imgRef.value[i].cla='eleRight';
                    }else if(imgRef.value[i].cla=='eleMid'){
                        imgRef.value[i].cla='eleLeft';
                    }else{
                        imgRef.value[i].cla='eleMid';
                    }
                }
            }
        }

        const change=(idx)=>{
            if(imgRef.value[idx].cla=='eleLeft'){
                move('right');
            }else if(imgRef.value[idx].cla=='eleRight'){
                move('left');
            }else{
                return;
            }
        }

        let pActive=ref(0);
        const getIdxByCla=(cla)=>{
            for(var i=0;i<imgRef.value.length;i++){
                if(imgRef.value[i].cla==cla){
                    return i;
                }
            }
        }
        watch(()=>{
            return imgRef.value[getIdxByCla('eleMid')].img
        },(newVal)=>{
            pActive.value=newVal;
        },{immediate:true})

        const setMidImg=(idx)=>{
            let midIdx=getIdxByCla('eleMid');
            let leftIdx=getIdxByCla('eleLeft');
            let rightIdx=getIdxByCla('eleRight');
            imgRef.value[midIdx].img=idx;
            imgRef.value[leftIdx].img=(idx-1+imgs.value.length)%imgs.value.length;
            imgRef.value[rightIdx].img=(idx+1+imgs.value.length)%imgs.value.length;
        }

        const mouChange=(idx)=>{
            stop();
            let refIdx=getIdxByCla('eleMid');
            if(imgRef.value[refIdx].img==idx){
                return;
            }else if(imgRef.value[refIdx].img==(idx+1+imgs.value.length)%imgs.value.length){
                move('right');
            }else if(imgRef.value[refIdx].img==(idx-1+imgs.value.length)%imgs.value.length){
                move('left');
            }else if(imgRef.value[refIdx].img<idx){
                move('left');
                setMidImg(idx);
            }else{
                move('right');
                setMidImg(idx);
            }
        }

        // 自动播放
        let timer = null
        const autoPlayFn = () => {
        clearInterval(timer)
            timer = setInterval(() => {
                move('left');
            }, props.duration)
        }
        watch(() => props.sliders, (newVal) => {
            // 有数据&开启自动播放，才调用自动播放函数
            if (newVal.length) {
                autoPlayFn()
            }
        }, { immediate: true })

        const stop = () => {
            if (timer) clearInterval(timer)
        }
        const start = () => {
            autoPlayFn()
        }

        // 组件销毁，清理定时器
        onUnmounted(() => {
            clearInterval(timer)
        })

        return {imgRef,change,imgs,pActive,mouChange,stop,start}
    }
}
</script>

<style lang="less" scoped>
//@import './variavles.less';
@carWidth:1200px;
@carHeight:250px;
@midWidth:700px;
@midRad:20px;
@ratio:0.8;
.imgContent{
    //width: 1100px;
    width: @carWidth;
    margin: auto;
    //border: solid;
    height: @carHeight;
    position: relative;
    div{
        overflow: hidden;
        span{
            //width: 60px;
            height: 30px;
            line-height: 30px;
            background-color: black;
            padding-left: 10px;
            padding-right: 10px;
            text-align: center;
            color:white;
            position:absolute;
            right: 0px;
            bottom: 0px;
            border-radius: 10px 0 0 0;
        }
        img{
            width: 100%;
            height: 100%;
            &:hover{
                cursor: pointer;
            }
            //object-fit: cover;
        }
    }
    
    .points{
        display: flex;
        position:absolute;
        justify-content: space-around;
        //border: solid;
        width: 200px;
        bottom: 10px;
        left: ((@carWidth)-100)/2;
        z-index: 999;
        div{
            width:14px;
            height: 14px;
            border-radius: 7px;
            background-color: rgb(196, 192, 192);
            &:hover{
                cursor: pointer;
            }
        }
        .active{
            background-color: black;
        }
    }
}

.eleLeft{
    transition: all .6s;
    // width: 560px;
    // height: 320px;
    width:@midWidth*@ratio;
    height: @carHeight*@ratio;
    left: 0px;
    top: ((@carHeight)-(@carHeight*@ratio))/2;
    //top:40px;
    box-shadow: 0 3px 8px rgba(0,0,0,0.2);
    border-radius: @midRad*@ratio;
    z-index: 880;
    position:absolute;
}
.eleMid{
    transition: all .6s;
    width: @midWidth;
    height: @carHeight;
    left: ((@carWidth)-(@midWidth))/2;
    //left: 200px;
    box-shadow: 0 3px 8px rgba(0,0,0,0.2);
    border-radius: @midRad;
    z-index: 900;
    position:absolute;
}

.eleRight{
    transition: all .6s;
    width: @midWidth*@ratio;
    height: @carHeight*@ratio;
    left: (@carWidth)-(@midWidth*@ratio);
    top:((@carHeight)-(@carHeight*@ratio))/2;
    box-shadow: 0 3px 8px rgba(0,0,0,0.2);
    border-radius:@midRad*@ratio;
    z-index: 880;
    position:absolute;
}


</style>

